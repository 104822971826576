<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header :type="2"></nav-header>

    <div class="app-container notes">
      <div class="notes-box">
        <h1 class="notes-box-h1">
          考試前請確保以下事情
        </h1>
        <h2 class="notes-box-h2">
          以確保公平性以及考試順利進行
        </h2>
        <div class="notes-box-div">
          <div>
            <img src="@/assets/image/test/notes1.png" alt="">
            <h4>考試期間請勿離席</h4>
            <h5>請先如廁</h5>
          </div>
          <div>
            <img src="@/assets/image/test/notes2.png" alt="">
            <h4>桌面請淨空</h4>
            <h5>
              除應試用品外<br />
              桌面請保持淨空
            </h5>
          </div>
          <div>
            <img src="@/assets/image/test/notes3.png" alt="">
            <h4>房間只有考生本人</h4>
            <h5>
              考試結束後經檢視畫面，<br />
              若有違規將會取消考試資格
            </h5>
          </div>
          <div>
            <img src="@/assets/image/test/notes4.png" alt="">
            <h4>房間內光源充足</h4>
            <h5>
              以免監測系統<br />
              無法清楚拍攝
            </h5>
          </div>
          <div>
            <img src="@/assets/image/test/notes5.png" alt="">
            <h4>考試過程請勿配戴口罩</h4>
          </div>
        </div>
        <div class="notes-box-check" @click="checkStatus1 = !checkStatus1">
          <img v-show="!checkStatus1" src="@/assets/image/test/icon-check.png" alt="">
          <img v-show="checkStatus1" src="@/assets/image/test/icon-checks.png" alt="">
          為確保考試權益，請務必全程開啟鏡頭與螢幕分享
        </div>
        <div class="notes-box-check" @click="checkStatus2 = !checkStatus2">
          <img v-show="!checkStatus2" src="@/assets/image/test/icon-check.png" alt="">
          <img v-show="checkStatus2" src="@/assets/image/test/icon-checks.png" alt="">
          再次確認以上事項，正式考試時須完成勾選才能開始考試
        </div>
        <div class="notes-box-button" :class="checkStatus1 && checkStatus2 ? '' : 'notes-box-buttons'" @click="examination">
          完成檢測
        </div>
      </div>
    </div>
    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: 'Notes',
  data() {
    return {
      checkStatus1: false,
      checkStatus2: false,
    }
  },
  methods: {
    // 開始考試
    examination() {
      if (this.checkStatus1 && this.checkStatus2) {
        // window.location.replace('http://jept.joy.com.tw/')
        window.location.href = 'http://jept.joy.com.tw/'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.notes {
  .notes-box {
    margin: 40px auto;
    width: 1430px;
    text-align: center;
    .notes-box-h1 {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 53px;
      text-align: center;
      color: #1D1B19;
    }
    .notes-box-h2 {
      margin: 15px 0 35px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 35px;
      text-align: center;
      color: #939393;
    }
    .notes-box-div {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        width: 269px;
        height: 359px;
        border: 1px solid #0C4FA2;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        h4 {
          z-index: 2;
          position: absolute;
          top: 224px;
          left: 0;
          width: 100%;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 150%;
          text-align: center;
          color: #FFFFFF;
        }
        h5 {
          z-index: 2;
          position: absolute;
          top: 267px;
          left: 0;
          width: 100%;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 150%;
          text-align: center;
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
    .notes-box-check {
      padding-top: 10px;
      margin: 0 auto;
      cursor: pointer;
      user-select: none;
      width: 650px;
      display: flex;
      align-items: center;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #666666;
      img {
        margin-right: 10px;
        width: 30px;
        height: 30px;
      }
    }
    .notes-box-button {
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px auto 0;
      width: 300px;
      height: 68px;
      background: #0C4FA2;
      border-radius: 12px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 40px;
      color: #FFFFFF;
      img {
        margin-left: 10px;
        width: 30px;
        height: 30px;
      }
    }
    .notes-box-buttons {
      cursor: not-allowed;
      background: rgba(12, 79, 162, 0.5);
    }
  }
}
</style>